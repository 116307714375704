import { lazy } from "react";
import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
// import MapRoutes from "./MapRoutes";
// import AuthenticationRoutes from "./AuthenticationRoutes";
// import Loadable from "ui-component/Loadable";

// const PagesLanding = Loadable(lazy(() => import("views/pages/landing")));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  console.log("start ThemeRoutes");
  // return useRoutes([{ path: '/', element: <PagesLanding /> }, AuthenticationRoutes, LoginRoutes, MainRoutes]);
  // return useRoutes([LoginRoutes, AuthenticationRoutes, MapRoutes, MainRoutes]); // AuthenticationRoutes不要のため削除
  return useRoutes([LoginRoutes, MainRoutes]);
}
