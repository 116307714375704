// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axiosServices from "utils/axios";
import FirebaseContext from "contexts/FirebaseContext";

// sample imports
import axios from "axios";

import { dispatch } from "../index";
import { useContext } from "react";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  products: [],
  coupons: [],
  coupon: null,
  product: null,
  relatedProducts: [],
  reviews: [],
  addresses: [],
};

const authToken =
  "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE0ZWI4YTNiNjgzN2Y2MTU4ZWViNjA3NmU2YThjNDI4YTVmNjJhN2IiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiWGtoblRtYVp3U05vVFF1cUJ1NlQiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZmluZG91dC1hcHAtN2Y0NmEiLCJhdWQiOiJmaW5kb3V0LWFwcC03ZjQ2YSIsImF1dGhfdGltZSI6MTY4OTkxMjkxMCwidXNlcl9pZCI6IlUyNjQ4YjJjYjcyMTk3NzQ3NjZmNWNlMzFlNTY2MjBkYiIsInN1YiI6IlUyNjQ4YjJjYjcyMTk3NzQ3NjZmNWNlMzFlNTY2MjBkYiIsImlhdCI6MTY4OTkxMjkxMCwiZXhwIjoxNjg5OTE2NTEwLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7fSwic2lnbl9pbl9wcm92aWRlciI6ImN1c3RvbSJ9fQ.M526j_Sf5s2q85M8fO6Xb8jJbXiASp3HcH--9YIWKxyXrGhu7-CkK3bHj79AnkBT1UOiudAijLNTfbaShB22yG1krLwau2T5XjAe4ht_v-ky7nsE3diREu3J1iyZGBknQzu7XGnyJ8carF2XZhQwWQNLmJXEx9sY7wxenl9rZ-iBcyi1YIQa1elCOENm7nYRUYkrxFo6O1sk3p1BgpcLVrRLn1ybndFqPfYEo6s0NGIE7ODxMOzPGke8aGLjlUiBB98hpNe8wFOliIThu5RF3MZb2cwZiuThTJ0D2i3MXGVhfyok6eUbGB0-BOR8apP6gAb6jLvk80cMU-VLxH_1jw";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${authToken}`,
};

const myUserInfo = {
  uuid: "U2648b2cb7219774766f5ce31e56620db",
  userId: "XkhnTmaZwSNoTQuqBu6T",
  channelId: "1661432408",
  ascdesc: "desc", //セットクーポンを昇順に表示する
  //利用可能ステータスだった場合に、利用済みステータスのチケット一覧を取得する
  status: 1,
  isUsed: false,
};

const slice = createSlice({
  name: "reward",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET MYCOUPONS
    getMyCouponItemsSuccess(state, action) {
      state.coupons = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.products = action.payload;
    },

    // FILTER PRODUCTS
    filterProductsSuccess(state, action) {
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.product = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedProductsSuccess(state, action) {
      state.relatedProducts = action.payload;
    },

    // GET PRODUCT REVIEWS
    getProductReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// export function getProducts() {
//   return async () => {
//     try {
//       const response = await axios.get(
//         "https://mock-data-api-nextjs.vercel.app/api/products/list"
//       );
//       dispatch(slice.actions.getProductsSuccess(response.data.products));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// MyCoupon取得
export function GetMyCouponItems() {
  console.log("start GetMyCouponItems");
  const firebaseContext = useContext(FirebaseContext);
  const sendJson = JSON.stringify(myUserInfo);

  console.log("store内でのfirebaseContext", firebaseContext);
  return async () => {
    console.log(" return start GetMyCouponItems");
    try {
      const response = await axios.post(
        "https://us-central1-findout-app-7f46a.cloudfunctions.net/actionsgen2/myCouponItems",
        sendJson,
        {
          headers: headers,
        }
      );

      console.log("GetMyCouponItems response", response);

      dispatch(slice.actions.getMyCouponItemsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterProducts(filter) {
  return async () => {
    try {
      const response = await axios.post(
        "https://mock-data-api-nextjs.vercel.app/api/products/filter",
        { filter }
      );
      dispatch(slice.actions.filterProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(id) {
  return async () => {
    try {
      const response = await axios.post(
        "https://mock-data-api-nextjs.vercel.app/api/product/details",
        { id }
      );
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedProducts(id) {
  return async () => {
    try {
      const response = await axios.post(
        "https://mock-data-api-nextjs.vercel.app/api/product/related",
        { id }
      );
      dispatch(slice.actions.getRelatedProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductReviews() {
  return async () => {
    try {
      const response = await axios.get(
        "https://mock-data-api-nextjs.vercel.app/api/review/list"
      );
      dispatch(
        slice.actions.getProductReviewsSuccess(response.data.productReviews)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get(
        "https://mock-data-api-nextjs.vercel.app/api/address/list"
      );
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post(
        "https://mock-data-api-nextjs.vercel.app/api/address/new",
        address
      );
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post(
        "https://mock-data-api-nextjs.vercel.app/api/address/edit",
        address
      );
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
