import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import { DASHBOARD_PATH } from "config";
import useLocalStorage from "hooks/useLocalStorage";

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * ログイン、サインアップに対してのみの認証ガード
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
  const { isLoggedIn, user, param } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("GuestGuard navigate", navigate);

  console.log("GuestGuard", location);
  console.log("GuestGuard isLoggedIn", isLoggedIn);
  console.log("GuestGuard param", param);
  console.log("GuestGuard user", user);

  // liffパラメータの取得
  const queryString = decodeURIComponent(location.search).replace(
    "?liff.state=",
    ""
  );

  console.log("GuestGuard queryString", queryString);

  let qChannelId;
  let qStoreId;

  // const query = new URLSearchParams(queryString);
  // const qChannelId = query.get("channelId");
  // const qStoreId = query.get("id");
  // console.log("クエリパラメータ channelId", qChannelId);
  // console.log("クエリパラメータ shopId", qStoreId);

  const url = new URL(window.location.href);
  console.log("GuestGuard location", location);
  console.log("GuestGuard search", decodeURI(location?.search));
  let urlPath;

  if (location?.state?.urlPath) {
    //location.stateにurlPathが残っている時用
    urlPath = location?.state?.urlPath;
    qChannelId = location?.state?.qChannelId;
    qStoreId = location?.state?.qStoreId;
  } else {
    //location.stateにurlPath残っていない時用
    urlPath = queryString.replace("/", "");
    urlPath = urlPath.substring(0, urlPath.indexOf("?"));
  }

  console.log("GuestGuard urlPath（navigate先のパス）", urlPath);

  // const qChannelId = url.searchParams.get("channelId");
  // const qChannelId = location.state.qChannelId;
  // const qStoreId = url.searchParams.get("id");
  // const qStoreId = location.state.qStoreId;
  // console.log("クエリパラメータ", qChannelId, qStoreId);

  useEffect(() => {
    if (isLoggedIn || location?.state?.findoutUserId) {
      // ユーザ作成後のステータスを付加する
      console.log("start navigate urlPath");
      console.log("navigate前 urlPath", urlPath);
      navigate(urlPath, {
        state: { qChannelId: qChannelId, qStoreId: qStoreId },
        replace: true,
      });
    }
    console.log("useEffectEnd");
  }, [isLoggedIn, navigate, location]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
