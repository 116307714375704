import axios from "axios";

// ApiAccess Functions
/**
 */
export const getCustomToken = async (providerUserId) => {
  console.log("start getCustomToken");
  let retParam;
  try {
    // signInWithCustomtokenでのAuth作成処理
    console.log("start 3.2 firebaseにログインしていない場合");
    // 3.2 firebaseにログインしていない場合
    // 3.2.1 LIFF APIを利用して、LINEのアクセストークンを取得
    const getCustomJson = JSON.stringify({
      uuid: providerUserId,
    });
    console.log("送信前Json", getCustomJson);
    // 3.2.3 LINEのIDトークンをfirebase functionsに投げて、firebaseのカスタム認証用トークンを取得
    const NoAuthHeaders = {
      "Content-Type": "application/json",
    };
    const getCustomTokenUrl = `${process.env.REACT_APP_API_URL}actions1/getCustomToken`;
    await axios
      .post(getCustomTokenUrl, getCustomJson, {
        headers: NoAuthHeaders,
      })
      .then((result) => {
        console.log("result", result);
        retParam = result;
      });
  } catch (e) {
    console.log("カスタムトークン取得時にエラー発生", e);
    if (e.statusCode === 500) {
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end getCustomToken");
  return retParam;
};

export const getFindoutUser = async (data, headers) => {
  console.log("start getFindoutUser");
  let retParam;
  try {
    console.log("data", data);
    console.log("auth内, headers", headers);
    await axios
      .post(`${process.env.REACT_APP_API_URL}actions1/findoutUser/item`, data, {
        headers,
      })
      .then((result) => {
        console.log("result", result);
        retParam = result;
      });
  } catch (e) {
    if (e.statusCode === 500) {
      // findoutのユーザ作成処理に失敗
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end getFindoutUser");
  return retParam;
};

export const addFindoutUser = async (data, headers) => {
  console.log("start addFindoutUser");
  let retParam;
  try {
    console.log("data", data);
    console.log("auth内, headers", headers);
    await axios
      .post(`${process.env.REACT_APP_API_URL}actions1/findoutUser/Add`, data, {
        headers,
      })
      .then((result) => {
        console.log("result", result);
        retParam = result;
      });
  } catch (e) {
    if (e.statusCode === 500) {
      // findoutのユーザ作成処理に失敗
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end addFindoutUser");
  return retParam;
};

export const updateFindoutUser = async (data, headers) => {
  console.log("start updateFindoutUser");
  let retParam;
  try {
    console.log("data", data);
    console.log("auth内, headers", headers);
    await axios
      .post(`${process.env.REACT_APP_API_URL}actions1/findoutUser/Edit`, data, {
        headers,
      })
      .then((result) => {
        console.log("result", result);
        retParam = result;
      });
  } catch (e) {
    if (e.statusCode === 500) {
      // findoutのユーザ作成処理に失敗
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end updateFindoutUser");
  return retParam;
};

export const getFindoutUserByProvider = async (data, headers) => {
  console.log("start getFindoutUserByProvider");
  let retParam;
  try {
    console.log("data", data);
    console.log("auth内, headers", headers);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}actions1/findoutUser/Provider`,
        data,
        {
          headers,
        }
      )
      .then((result) => {
        console.log("result", result);
        retParam = result;
      });
  } catch (e) {
    if (e.statusCode === 500) {
      // findoutのユーザ作成処理に失敗
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end getFindoutUserByProvider");
  return retParam;
};

export const updateUserProvider = async (data, headers) => {
  console.log("start updateUserProvider");
  let retParam;
  try {
    console.log("data", data);
    console.log("auth内, headers", headers);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}actions1/findoutUser/Provider/Add`,
        data,
        {
          headers,
        }
      )
      .then((result) => {
        console.log("result", result);
        retParam = result;
      });
  } catch (e) {
    if (e.statusCode === 500) {
      // findoutのユーザ作成処理に失敗
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end updateUserProvider");
  return retParam;
};

export const getProviderUser = async (data, headers) => {
  console.log("start getProviderUser");
  let retParam;
  try {
    console.log("data", data);
    console.log("auth内, headers", headers);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}actions1/providerUser/Item`,
        data,
        {
          headers,
        }
      )
      .then((result) => {
        console.log("result", result);
        retParam = result;
      });
  } catch (e) {
    if (e.statusCode === 500) {
      // findoutのユーザ作成処理に失敗
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end getProviderUser");
  return retParam;
};

export const getChannelInfo = async (data, headers) => {
  console.log("start getChannelInfo");
  let retParam;
  try {
    console.log("data", data);
    console.log("auth内, headers", headers);
    await axios
      .post(`${process.env.REACT_APP_API_URL}actions1/channel/item`, data, {
        headers,
      })
      .then((result) => {
        console.log("result", result);
        retParam = result;
      });
  } catch (e) {
    if (e.statusCode === 500) {
      // チャンネル関連グループ情報の取得に失敗
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end getChannelInfo");
  return retParam;
};

export const getChannelGroupInfo = async (data, headers) => {
  console.log("start getChannelGroupInfo");
  let retParam;
  try {
    console.log("data", data);
    console.log("auth内, headers", headers);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}actions1/channel/group/groupId`,
        data,
        {
          headers,
        }
      )
      .then((result) => {
        console.log("result", result);
        retParam = result;
      });
  } catch (e) {
    if (e.statusCode === 500) {
      // チャンネル関連グループ情報の取得に失敗
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end getChannelGroupInfo");
  return retParam;
};

// findoutUserでSignInしたChannelIDを返却する
export const getSignInChannel = async (data, channelType, headers) => {
  let signInChannelId;

  console.log("start getSignInChannel");

  const res = await getChannelGroupInfo(data, headers);

  console.log("res", res);
  const channels = res.data.items;
  console.log(channels);

  if (
    channels.findIndex((channel) => channel.data.type === channelType) != -1
  ) {
    const signInChannelIndex = channels.findIndex(
      (channel) => channel.data.type === channelType
    );

    signInChannelId = channels[signInChannelIndex].id;
  }

  console.log("signInChannelId", signInChannelId);
  console.log("end getSignInChannel");
  return signInChannelId;
};

// facebookでSignInした際のpgScopeIdとappScopeIDの照らし合わせ
export const metaBotAppLink = async (pgUserId, appUserId, accessToken) => {
  console.log("start metaBotAppLink");
  let retParam;
  const id = pgUserId ?? appUserId;
  const urlPath = pgUserId !== "" ? "ids_for_pages" : "ids_for_apps";
  try {
    await axios
      .get(
        `https://graph.facebook.com/v18.0/${id}/${urlPath}?access_token=${accessToken}`
      )
      .then((result) => {
        console.log("result", result);
        if (result.data.length > 1) {
          retParam = result.data; // appUserIdで検索する場合、関連付けられたidが複数返却される
        } else {
          retParam = result.data[0].id;
        }
      });
  } catch (e) {
    if (e.statusCode === 500) {
      // チャンネル関連グループ情報の取得に失敗
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end metaBotAppLink");
  return retParam;
};

// メールアドレス認証確認の送信
export const sendCustomVerificationEmail = async (email, name) => {
  console.log("start sendCustomVerificationEmail");
  console.log("email,name", email, name);
  let retParam;
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "",
  };

  const data = {
    token: `${process.env.REACT_APP_SENDGRID_API_KEY}`,
    personalizations: [
      {
        to: [
          {
            email: `${email}`,
          },
        ],
      },
    ],
    from: {
      email: `${process.env.REACT_APP_SENDMAIL_EMAIL}`,
    },
    subject: "メールアドレス確認のお願い",
    content: [
      {
        type: "text/plain",
        // value: `${email}様 \n\nメールアドレスを確認するには、次のリンクをクリックしてください。\n\n${link}\n\n このアドレスの確認を依頼していない場合は、このメールを無視してください。\n\n よろしくお願いいたします。 \n\n findout運営チーム`,
      },
    ],
  };
  try {
    await axios
      .post(`${process.env.REACT_APP_API_URL}sendgrid/sendMail`, data, {
        headers,
      })
      .then((result) => {
        console.log("result", result);
        retParam = result;
      });
  } catch (e) {
    if (e.statusCode === 500) {
      // チャンネル関連グループ情報の取得に失敗
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end sendCustomVerificationEmail");
  return retParam;
};

//外部APIアクセス
//
// facebookの管理ページリストを取得する
export const managePageList = async (userid, OAuthAccessToken) => {
  console.log("start managePageList");
  let retParam;
  try {
    await axios
      .get(
        `https://graph.facebook.com/v18.0/${userid}/accounts?access_token=${OAuthAccessToken}`
      )
      .then((result) => {
        console.log("result", result);
        retParam = result.data;
      });
  } catch (e) {
    if (e.statusCode === 500) {
      // チャンネル関連グループ情報の取得に失敗
      console.log(`eCode ${e.statusCode} ${e}`);
    }
  }
  console.log("retParam", retParam);
  console.log("end managePageList");
  return retParam;
};

// Not ApiAccess Utill Functions
/**
 */
export const getProviderId = (type) => {
  console.log("start getProviderId");
  let providerId;
  if (type?.includes("facebook")) {
    providerId = "page";
    // oidcのため、providerIDはIdPにて自由に設定可能
  } else if (type?.includes("page")) {
    providerId = "page";
  } else if (type?.includes("line")) {
    providerId = "line";
  } else if (type?.includes("instagram")) {
    providerId = "instagram";
  } else {
    providerId = type;
  }
  console.log("providerId", providerId);
  console.log("end getProviderId");
  return providerId;
};
