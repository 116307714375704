// material-ui
import { useTheme } from "@mui/material/styles";
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
// ==============================|| LOGO SVG ||============================== //
const Logo = () => {
  const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
     *
     */
    <svg
      width="150"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 480 131.09"
    >
      <g>
        <g>
          <path
            fill="#D70916"
            d="M296.4,45.17c11.4,0,20.65,9.25,20.65,20.65
            c0,11.41-9.25,20.65-20.65,20.65c-11.41,0-20.65-9.24-20.65-20.65C275.75,54.42,285,45.17,296.4,45.17L296.4,45.17z M333.37,47.94
            c-14.41-30.45-61.92-29.26-74.79,2.41c-7.08,17.44,1.98,37.33,14.56,54.22c9.14,12.26,21.77,26.52,23.26,26.52
            c1.53,0,15.18-14.7,24.43-27.18C333.71,86.52,341.49,65.11,333.37,47.94z"
          />
          <path
            fill="#970A12"
            d="M314.46,55.79c1.65,2.97,2.6,6.39,2.6,10.03
            c0,11.41-9.24,20.65-20.65,20.65v44.62l0.77-0.4c3.36-2.43,15.31-15.51,23.66-26.78c12.88-17.39,20.66-38.8,12.54-55.97
            c-0.38-0.81-0.79-1.59-1.22-2.36L314.46,55.79z"
          />
          <path
            fill="#D70916"
            d="M287.24,74.63c6.49,0,11.79-5.29,11.79-11.79
            c0-6.49-5.29-11.79-11.79-11.79c-6.49,0-11.79,5.29-11.79,11.79C275.45,69.34,280.75,74.63,287.24,74.63z"
          />
        </g>
        <path
          d="M30.93,32.19v-3.76c0-13.73,16.77-17.92,32.37-8.38l6.5-13.15
          C44.95-7.71,13.3,1.54,13.3,28.43v3.76H0v15.03h13.3v56.22h17.63V47.22h19.8v56.22h17.63V32.19H30.93L30.93,32.19z M158.55,103.44
          V66.01c0-22.4-13.44-34.98-30.21-34.98c-8.96,0-16.19,3.61-23.41,10.69l-1.16-9.68H88.02v71.4h17.49V67.02
          c0-10.84,7.37-19.95,18.21-19.95c11.27,0,17.2,8.24,17.2,19.08v37.29H158.55L158.55,103.44z M208.27,46.93
          c11.13,0,20.67,8.38,20.67,20.81c0,12.86-9.54,20.96-20.67,20.96c-11.27,0-20.23-8.53-20.23-20.96
          C188.04,54.88,197,46.93,208.27,46.93L208.27,46.93z M230.1,2.41v39.46c-4.19-7.37-15.9-11.42-23.41-11.42
          c-20.81,0-36.28,12.72-36.28,37.29c0,23.41,15.75,37.29,36.71,37.29c8.67,0,17.49-2.89,22.98-11.42l1.16,9.83h16.48V2.41H230.1
          L230.1,2.41z M346.45,32.19v37.43c0,22.11,12.43,34.98,29.48,34.98c9.97,0,16.77-3.47,24.14-10.69l1.16,9.54h15.75V32.19h-17.49
          v36.35c0,10.91-7.37,20.02-18.21,20.02c-11.27,0-17.2-8.24-17.2-19.08V32.19H346.45L346.45,32.19z M439.82,13.97v18.36h-13.3v15.18
          h13.3v30.79c0,17.78,10.12,26.59,25.58,26.02c5.35-0.15,9.54-1.01,14.6-3.04l-4.91-15.03c-2.6,1.3-5.78,2.17-8.53,2.17
          c-5.49,0-9.25-3.33-9.25-10.12V47.51h19.8V32.33h-19.66V12.09L439.82,13.97z"
        />
      </g>
    </svg>
  );
};
export default Logo;
