// action - account reducer
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const FIREBASE_STATE_CHANGED = "FIREBASE_STATE_CHANGED";
export const LOGINFAILED = "LOGINFAILED";
export const AUTHENTICATED = "AUTHENTICATED";
export const USERUPDATE = "USERUPDATE";
export const LINELOGIN = "LINELOGIN";
export const LOGINPROVIDERID = "LOGINPROVIDERID";
